import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Cookies = () => (
  <Layout>
    <Seo title="Evästeseloste" />
    <h1>Evästeseloste</h1>
    {/* <script
      id="CookieDeclaration"
      src="https://consent.cookiebot.com/e0b6da8f-70a1-421b-b299-fc281157e8a7/cd.js"
      type="text/javascript"
      async
    ></script> */}
  </Layout>
)

export default Cookies
